@import ../../../styles/helpers

.main
    position: relative
    background: #f4f5f7
    +dark
        background: $n3

.container
    display: flex
    align-items: center
    min-height: 500px
    padding-top: 20px
    padding-bottom: 20px
    +d
        padding-top: 80px
    +m
        display: block
        min-height: auto
        padding-top: 32px
        padding-bottom: 16px
    +dark
        color: $n1

.wrap
    position: relative
    z-index: 3
    +t
        max-width: 350px
    +m
        max-width: 100%
        margin-bottom: 16px
    +dark
        color: #fff

.stage
    margin-bottom: 12px

.title
    margin-bottom: 20px
    span
        color: $p4

.text
    margin-bottom: 24px
    color: $n4
    span
        color: $p4
    +dark
        color: #fff

.form
    max-width: 300px
    +m
        max-width: 100%
    
.bg
    position: absolute
    top: 100px
    right: calc(48% - 730px)
    width: 500px
    pointer-events: none
    +d
        right: calc(50% - 670px)
        width: 700px
    +m
        position: static
        width: 100%
        padding-left: 16px
    img
        width: 100%

.button
    margin-right: 10px

.firstSection
    width: 100%
    max-width: 866px
    padding: 20px 32px
    margin: 16px 0 0 
    border-radius: 16px
    overflow-x: auto
    margin-top: 1rem
    background-color: #ffffff
    max-height: 400px
    +m
        padding: 10px 16px
    +dark
        background: $n2
        color: #fff

.secondSection
    display: flex

.firstColumn
    color: #8e8e92
    font-size: .875rem
    line-height: 22px
    flex-shrink: 0
    min-width: 147px
    border-right: 1px solid #dedfe0
    +m
        min-width: 100%
        border-right: none
        border-bottom: 1px solid #dedfe0
        padding-bottom: 10px
    +dark
        background: $n2
        color: #fff

.even
    padding: 15px 0

.secondColumn
    padding: 0 16px
    flex: 1 1 0%
    border-right: 1px solid #dedfe0
    white-space: nowrap
    +m
        flex: 1 1 100%
        padding-left: 10px
        padding-right: 10px
        border-right: none
        border-bottom: 1px solid #dedfe0
        padding-bottom: 10px
    +dark
        background: $n2
        color: #fff

.secondFirstChild
    color: #151717
    font-size: .875rem
    line-height: 22px
    +m
        font-size: 0.75rem
    +dark
        background: $n2
        color: #fff
        
.secondSecondChild 
    padding-top: 15px
    padding-bottom: 15px
    color: #151717
    +m
        font-size: 0.75rem
    +dark
        background: $n2
        color: #fff


.secondColumn:last-child 
    border-right: none
    +m
        font-size: 0.75rem

.firstColumn:first-child
    border-right: none
    +m
        font-size: 0.75rem

.noteContainer
    background-color: #f8f9fa
    border-radius: 8px
    padding: 15px
    margin: 20px 0
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1)
    +dark
        background: $n2
        color: #fff
.noteText
    +caption-bold-1

.text
    margin: 32px 0px
    +body-bold-2
    color: $n4
    span
        color: $p4
    +t
        max-width: 300px
    