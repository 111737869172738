@import ../../../../styles/helpers

.table
    margin-top: 20px
    border-radius: 10px
    border: 2px solid $n6
    display: table
    width: 100%
    +t
        display: block

.form
    margin-top: 10px
    position: relative
    margin-bottom: 12px

.input
    width: 100%
    height: 40px
    padding: 0 40px 0 14px
    border-radius: 8px
    background: none
    border: 2px solid $n6
    +poppins
    +caption-2
    color: $n2
    transition: border-color .2s
    +dark
        border-color: $n3
        color: $n8
    +placeholder
        color: $n4
    &:focus
        border-color: $n4

.result
    position: absolute
    top: 0
    right: 0
    bottom: 0
    width: 40px
    svg
        fill: $n4
        transition: fill .2s
    &:hover
        svg
            fill: $p1

.row
    display: table-row
    color: $n2
    +t
        position: relative
        display: block
    &:first-child
        +t
            display: none
        .col
            padding: 6px
            border-bottom: 1px solid $n6
            +caption-bold-2
            color: $n4
            +dark
                border-color: $n2
            svg
                margin-left: 4px
                fill: $n4
    &:not(:first-child)
        transition: background .2s
        .col
            vertical-align: middle
            font-weight: 500
            &:first-child
                border-radius: 12px 0 0 12px
                color: $n4
                +t
                    border-radius: 0
            &:last-child
                border-radius: 0 12px 12px 0
                +t
                    border-radius: 0
        &:hover
            background: $n7
            +dark
                background: $n2
            .button
                display: inline-flex
            .chart
                display: none
    &:not(:last-child)
        +t
            margin-bottom: 24px
            padding-bottom: 20px
            border-bottom: 1px solid $n6
            +dark
                border-color: $n3

.col
    display: table-cell
    padding: 6px
    font-weight: 500
    +t
        display: flex
        align-items: center
        margin-bottom: 4px
        padding: 0
    +dark
        color: $n8
    &:first-child,
    &:nth-child(2)
        text-align: left
    &:nth-child(2)
        +t
            margin-bottom: 16px
    &:nth-last-child(2)
        +d
            display: none
    &:first-child
        font-size: 12px
        +t
            display: none
    &:last-child
        +t
            margin-bottom: 0

.supportButton
    cursor: pointer
    color: #fff
    +caption-1
    font-weight: 500
    background: #22767d
    padding: 5px 10px
    border-radius: 10px
    border: 1px solid $n6
    display: inline-block
    text-align: center
    +dark
        box-shadow: inset 0 0 0 2px $n3
        background: none
        color: $n7
    &:hover
        box-shadow: inset 0 0 0 2px $n4

.disabledButton
    cursor: pointer
    color: $n1
    +caption-1
    font-weight: 500
    background: $n6
    padding: 5px 10px
    border-radius: 10px
    border: 1px solid $n6
    display: inline-block
    text-align: center
    box-shadow: inset 0 0 0 2px $n4
    +dark
        box-shadow: inset 0 0 0 2px $n3
        background: none
        color: $n7
    
.checkMark
    color: green
    font-size: 30px
