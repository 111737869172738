@import ../../../styles/helpers

.list
    display: flex
    flex-wrap: wrap
    margin: -32px -16px 0
    +m
        display: block
        margin: 0

.card
    flex: 0 0 calc(33.333% - 32px)
    width: calc(33.333% - 32px)
    margin: 32px 16px 0
    +d
        flex: 0 0 calc(50% - 32px)
        width: calc(50% - 32px)
    +m
        width: 100%
        margin: 0
        &:not(:last-child)
            margin-bottom: 32px
    &:nth-child(n+4)
        display: none
        +d
            display: block
    &:nth-child(n+5)
        +d
            display: none

.btns
    margin-top: 32px
    text-align: center
    