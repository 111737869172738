@import ../../styles/helpers

.button
    position: relative
    svg
        position: absolute
        top: 50%
        left: 50%
        transform: translate(-50%,-50%)
        transition: all .2s
        &:nth-child(2)
            z-index: 2
            opacity: 0
    &:hover
        box-shadow: inset 0 0 0 2px $n2
        background: $n2
        +dark
            box-shadow: 0 0 0 2px $n3 inset
        svg
            fill: $n8
    &.active
        box-shadow: inset 0 0 0 2px $n2
        background: $n2
        color: $n2
        svg
            fill: $n8
            &:first-child
                opacity: 0
            &:nth-child(2)
                opacity: 1
        +dark
            background: $n3
            box-shadow: 0 0 0 2px $n3 inset

