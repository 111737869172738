@import ../../../styles/helpers

.container
    max-width: 888px

.content
    h1
        font-size: 64px
        line-height: 1
        letter-spacing: -.02em
        +m
            font-size: 48px
            line-height: calc(56/48)
    h2
        font-size: 48px
        line-height: calc(56/48)
        letter-spacing: -.02em
        +m
            font-size: 40px
            line-height: calc(48/40)
            letter-spacing: -.01em
    h3
        font-size: 40px
        line-height: calc(48/40)
        letter-spacing: -.01em
        +m
            font-size: 32px
            line-height: calc(40/32)
    h4
        font-size: 32px
        line-height: calc(40/32)
        letter-spacing: -.01em
    h1,
    h2,
    h3,
    h4
        position: relative
        margin-bottom: 12px
        padding-bottom: 12px
        +dm-sans
        font-weight: 700
        &:after
            content: ""
            position: absolute
            left: 0
            bottom: 0
            width: 166px
            height: 2px
            background: $n6
            +dark
                background: $n3
        &:not(:first-child)
            margin-top: 20px
            +m
                margin-top: 40px
    p
        +body-2
        color: $n4
        &:not(:last-child)
            margin-bottom: 12px
    figure
        margin: 0 -76px
        +t
            margin-left: 0
            margin-right: 0
        img
            width: 100%
            border-radius: 16px
            +m
                min-height: 360px
                object-fit: cover
        &:not(:first-child)
            margin-top: 80px
            +m
                margin-top: 40px

.actions
    position: relative
    display: flex
    justify-content: center
    margin-top: 80px
    +m
        margin-top: 32px

.actions > div,
.favorite
    &:not(:last-child)
        margin-right: 32px
    
.link
    text-decoration: underline

.table
    border: 2px solid $n7
    margin: 20px 0
    display: table
    width: 100%
    +t
        display: block

.row
    display: table-row
    color: $n2
    +t
        position: relative
        display: block
    &:first-child
        +t
            display: none
        .col
            padding: 6px
            border-bottom: 1px solid $n6
            +caption-bold-2
            color: $n4
            +dark
                border-color: $n2
            svg
                margin-left: 4px
                fill: $n4
    &:not(:first-child)
        transition: background .2s
        .col
            vertical-align: middle
            font-weight: 500
            &:first-child
                border-radius: 12px 0 0 12px
                color: $n4
                +t
                    border-radius: 0
            &:last-child
                border-radius: 0 12px 12px 0
                +t
                    border-radius: 0
        &:hover
            background: $n7
            +dark
                background: $n2
            .button
                display: inline-flex
            .chart
                display: none
    &:not(:last-child)
        +t
            margin-bottom: 24px
            padding-bottom: 20px
            border-bottom: 1px solid $n6
            +dark
                border-color: $n3

.col
    display: table-cell
    padding: 6px
    font-weight: 500
    +t
        display: flex
        align-items: center
        margin-bottom: 4px
        padding: 0
    +dark
        color: $n8
    &:first-child,
    &:nth-child(2)
        text-align: left
    &:nth-child(2)
        +t
            margin-bottom: 16px
    &:nth-last-child(2)
        +d
            display: none
    &:first-child
        font-size: 12px
        +t
            display: none
    &:last-child
        +t
            margin-bottom: 0

.strongText
    +caption-bold-1
    font-weight: 900
    