@import ../../styles/helpers

.top
    margin-bottom: 32px
    padding-bottom: 32px
    border-bottom: 1px solid $n6
    +dark
        border-color: $n3

.title
    margin-bottom: 32px
    text-align: center

.info
    margin-bottom: 12px
    text-align: center
    +caption-2
    color: $n4

.correct
    display: flex
    justify-content: center
    align-items: center
    height: 40px
    padding: 0 24px
    background: $n7
    border-radius: 20px
    font-weight: 500
    color: $p4
    +dark
        background: $n3
    svg
        margin-right: 4px
        fill: $p4
    span
        color: $n2
        +dark
            color: $n8