@import ../../../../styles/helpers

.card
    width: 100%
    padding: 24px 24px
    border-radius: 20px
    background: $n8
    text-align: center
    transition: all .2s
    &:hover
        box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1)
    +dark
        background: $n2
        box-shadow: inset 0 0 0 2px $n2
        &:hover
            background: transparent

.preview
    display: flex
    justify-content: center
    align-items: center
    height: 160px
    margin: 0 auto 8px
    img
        max-width: 100%
        max-height: 100%
    
.title
    margin-bottom: 16px
    +body-bold-2

.content
    margin-bottom: 16px
    color: $n4