@import ../../styles/helpers

.buttonContainer
    display: flex
    gap: 10px
    justify-content: left
    margin: 0 0 20px 10px

.button
    background: $n4
    color: white
    padding: 10px 20px
    text-align: center
    font-size: 16px
    cursor: pointer
    border-radius: 5px
    transition: background-color 0.3s ease, transform 0.3s ease

    &:hover
        background: #22767d
        transform: scale(1.05)

    &:active
        background: #22767d
    
    &.selected
        background: #22767d

.button:focus
    outline: none

.buttonContainer button
    font-weight: 300