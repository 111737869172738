@import ../../../../../styles/helpers

.coinCard
    flex: 0 0 auto
    width: 320px
    background-color: #fff
    border-radius: 8px
    border: 2px solid $n6
    padding: 15px
    +dark
        background-color: #2b2b2f
        border: 2px solid #444
    +m
        width: 100%

.coinCardLink
    display: flex
    justify-content: space-between
    padding: 5px
    text-decoration: none
    color: inherit

.coinTitle
    +caption-bold-1 
    font-weight: 600

.coinMore
    +caption-bold-2
    color: #3498db
    +dark
        color: #3498db

.coinIcon
    width: 16px
    height: 16px
    margin-left: 5px

.coinList
    padding: 10px 0

.coinItem
    display: flex
    justify-content: space-between
    padding: 8px 0
    border-bottom: 1px solid #f1f1f1
    text-decoration: none
    color: inherit
    &:hover
        background: $n7
        +dark
            background: $n2
            color: $n1
    +dark
        border-bottom: 1px solid #444 
    +m
        padding: 8px 0

.coinItem:last-child
    border-bottom: none

.coinInfo
    display: flex
    align-items: center
    flex: 1 
    text-align: left 
    min-width: 100px

.coinLogo
    width: 24px
    height: 24px
    margin-right: 10px

.coinSymbol
    font-weight: 500
    +dark
        color: $n8

.coinInfo
    display: flex
    align-items: center
    flex: 1 
    text-align: left
    min-width: 100px

.coinPrice
    +caption-bold-2
    flex: 1 
    text-align: left
    font-weight: 600
    +dark
        color: $n8

.coinChange
    +caption-bold-2
    color: green
    flex: 0 0 auto 
    text-align: right 
    min-width: 60px
    +dark
        color: $n8

.negative
    color: $p3

.noData
    +caption-bold-1
    text-align: center
    color: $n4
    font-weight: 500
    padding: 20px 0
    +dark
        color: $n8

.filterIcon 
    display: flex
    justify-content: center
    align-items: center
    width: 50px
    border: 1px solid $n6
    border-radius: 10px

.icons
    display: flex
    justify-content: space-evenly
    width: 100px
    
.positive
    padding: 2px 5px
    color: $p4
    border-radius: 14px
    background: none
    +button-2
    transition: all .2s
    cursor: pointer
    &:hover
        color: $p4
        +dark
            color: $p4
    &.active
        padding: 2px 5px
        background: $n6
        color: $p4
        +dark
            background: $n3
            color: $p4
            &:hover
                color: $p4
            
.negative
    padding: 2px 5px
    color: $p3
    cursor: pointer
    border-radius: 14px
    background: none
    +button-2
    transition: all .2s
    &:hover
        color: $p3
        +dark
            color: $p3
    &.active
        background: $n6
        padding: 2px 5px
        color: $p3
        +dark
            background: $n3
            color: $p3
            &:hover
                color: $p3
