@import ../../../styles/helpers

.details
    position: relative
    margin-top: -137px

.container
    width: 100%
    max-width: 1300px
    margin: 0 auto
    +t
        padding: 0 40px
    +m
        padding: 0 32px