@import ../../../../styles/helpers

.item
    position: relative
    display: flex
    flex-direction: row-reverse
    color: $n2
    transition: color .2s
    +t
        display: block
    +dark
        color: $n8
    &:hover
        color: $p1
        .arrow
            transform: translateX(10px)
            svg
                fill: $p1

.preview
    flex-shrink: 0
    width: 640px
    margin-left: auto
    +d
        width: 500px
    +t
        width: 100%
        margin-bottom: 32px
    img
        width: 100%
        border-radius: 16px
        +t
            min-height: 240px
            object-fit: cover

.details
    display: flex
    flex-direction: column
    align-items: flex-start
    width: 352px
    +d
        width: auto
        padding-right: 32px
    +t
        padding-right: 0

.category
    margin-bottom: auto

.currency
    margin-bottom: 20px
    +body-2
    color: $n3
    +dark
        color: $n5

.content
    margin-bottom: auto
    color: $n4

.arrow
    position: absolute
    top: calc(100% + 14px)
    left: 0
    width: 24px
    height: 24px
    transition: transform .2s
    +t
        position: static
        margin-top: 16px
    svg
        fill: $n4
        transition: fill .2s
    
.cardContent
    border: 2px solid #EBEBEB
    border-radius: 10px
    padding: 20px
    +dark
        border: 2px solid $n2

.text
    font-size: 16px

.card
    display: flex
    flex-direction: column
    align-items: flex-start
    margin: 16px 0
    padding: 16px
    border: 1px solid #EBEBEB
    border-radius: 12px
    background-color: #ffffff
    cursor: pointer
    +dark
        color: $n8
        background: $n2

.card:hover
    background: $n8
    +dark
        background: $n2

.card .leftcolumn
    display: flex
    margin-top: 10px

.card .leftcolumn .icon
    width: 32px
    height: 32px
    margin-bottom: 8px

.card .leftcolumn .title
    margin: 10px
    font-weight: 500
    font-size: 16px
    line-height: 22px
    color: #1DA2B4
    +dark
        color: $n8
        background: $n2

.card .middlecolumn
    margin-top: 12px
    flex: 1
    color: #232323
    font-size: 14px
    line-height: 22px
    +dark
        color: $n8
        background: $n2

.card .rightcolumn
    margin-top: 36px

.card .rightcolumn .iconcontainer
    display: inline-block
    padding: 12px 16px

.card .rightcolumn .icon
    width: 24px
    height: 24px
    margin-right: 8px

.card.dark
    border-color: #393939
    background-color: #1a1a1a

.card.dark .title
    color: #EFF1F1

.card.dark .middlecolumn
    color: #EFF1F1
