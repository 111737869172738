@import ../../../styles/helpers


.row
    display: table-row
    color: $n2
    &:first-child
        .col
            padding: 24px 16px
            border-bottom: 1px solid $n6
            color: $n4
            +m
                padding: 8px
                &:nth-child(2)
                    padding-left: 0
                &:last-child
                    padding-right: 0
            +dark
                border-color: $n3
    &:not(:first-child)
        transition: background .2s
        .col
            vertical-align: middle
            +body-bold-2
            +m
                font-size: 12px
            &:first-child
                border-radius: 12px 0 0 12px
                color: $n4
                +t
                    border-radius: 0
            &:last-child
                border-radius: 0 12px 12px 0
                +t
                    border-radius: 0
        &:hover
            background: $n7
            +t
                background: none
            +dark
                background: $n2

.col
    display: table-cell
    padding: 4px 16px
    +t
        &:nth-child(5)
            display: none
    +m
        padding: 6px 8px
        font-size: 12px
        &:first-child,
        &:nth-child(6)
            display: none
        &:nth-child(2)
            padding-left: 0
        &:last-child
            padding-right: 0
    +dark
        color: $n8