@import ../../../../styles/helpers

.item
    transition: background .2s
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
    &:hover,
    &.active
        background: #F8F8F9
        +dark
            background: $n2
    &.active
        .btns
            display: flex
    &:not(:last-child)
        border-bottom: 1px solid $n6
        +dark
            border-color: $n2
            +t
                border-color: $n3

.row
    display: flex
    padding: 10px 0 10px

.col
    flex: 1
    padding: 3px 16px 2px 32px
    +caption-bold-2
    color: $n3
    +dark
        color: $n6

.currency
    display: flex
    .text
        font-weight: 400

.icon
    flex-shrink: 0
    width: 32px
    margin-right: 20px
    img
        width: 100%

.info
    font-weight: 500
    font-size: 12px

.text
    font-weight: 500
    font-size: 11px
    color: $n4

.btns
    display: none
    margin-top: -10px 
    padding: 0 32px 20px
    +m
        padding: 0 16px 20px

.btnClass
    border-bottom: 1px solid $n6
    +dark
        background: $n8
        border-color: $n2
        +t
            border-color: $n3