@import ../../../styles/helpers

.top
    margin-bottom: 32px
    padding-bottom: 32px
    border-bottom: 1px solid $n6
    .info
        margin-bottom: 20px

.title
    margin-bottom: 32px
    text-align: center

.info
    text-align: center
    +caption-2
    color: $n4

.btns
    display: flex
    justify-content: center
    margin: 0 -10px
    .button
        flex: 0 0 calc(50% - 20px)
        margin: 0 10px
        &:not(:last-child)
            margin-right: 12px
        
.field
    &:not(:last-child)
        margin: 8px

.form > .button
    width: 100%
    margin-top: 8px

.form > .info
    margin-bottom: 32px

.danger
    color: $p3