@import ../../../styles/helpers
.wrap
    padding: 20px 0 0
    +m
        padding: 0

.line
    display: flex
    align-items: center
    padding: 0 32px
    +m
        display: none

.form
    position: relative
    flex-shrink: 0
    width: 256px
    margin-right: auto

.input
    width: 100%
    height: 40px
    padding: 0 40px 0 14px
    border-radius: 20px
    background: none
    border: 2px solid $n6
    +poppins
    +caption-2
    color: $n2
    transition: border-color .2s
    +dark
        border-color: $n3
        color: $n8
    +placeholder
        color: $n4
    &:focus
        border-color: $n4

.result
    position: absolute
    top: 0
    right: 0
    bottom: 0
    width: 40px
    svg
        fill: $n4
        transition: fill .2s
    &:hover
        svg
            fill: $p1

.link
    display: flex
    align-items: center
    +poppins
    +caption-2
    font-weight: 500
    color: $n4
    transition: color .2s
    svg
        margin-left: 4px
        fill: $n4
        transition: fill .2s
    &:hover
        color: $n2
        svg
            fill: $n2
        +dark
            color: $n8
            svg
                fill: $n8

.row
    display: flex
    border-bottom: 1px solid $n6
    +dark
        border-color: $n2

.col
    flex: 1
    padding: 10px
    +caption-bold-2
    color: $n3
    +dark
        color: $n6
    
.btns
    margin-top: 64px
    text-align: center
    +m
        margin-top: 48px

.customImage
    width : 300px

.customButton
    margin: 15px 0px
    text-align: center
    +m
        margin-top: 48px

.title
    padding: 10px
    border-bottom: 1px solid $n5

.container
    max-width: 1280px
    padding: 0

.newContainer
    margin-bottom: 20px