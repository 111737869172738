@import ../../styles/helpers

.successfully
    padding-top: 40px

.title
    margin-bottom: 32px
    text-align: center

.info
    max-width: 280px
    margin: 0 auto 32px
    text-align: center
    +body-bold-2
    span
        color: $p4

.list
    display: flex
    flex-wrap: wrap
    padding: 24px
    border-radius: 12px
    border: 1px solid $n6
    +dark
        border-color: $n3

.item
    &:first-child
        margin-right: auto

.category
    margin-bottom: 10px
    color: $n4

.content
    font-weight: 500
    color: $n2
    +dark
        color: $n8

.btns
    display: flex
    margin: 32px -8px 0

.button
    flex: 0 0 calc(50% - 16px)
    width: calc(50% - 16px)
    margin: 0 8px

.loaderContainer 
  top: 0px
  left: 0px
  position: fixed
  text-align: center
  background-color: #000
  width: 100%
  height: 100%
  z-index: 1051
  opacity: 0.5


.spinner 
  animation: rotate 2s linear infinite
  z-index: 2
  position: absolute
  top: 50%
  left: 50%
  margin: -25px 0 0 -25px
  width: 50px
  height: 50px


.path 
  stroke: #93bfec
  stroke-linecap: round
  animation: dash 1.5s ease-in-out infinite


@keyframes rotate 
  100% 
    transform: rotate(360deg)
  

@keyframes dash 
  0% 
    stroke-dasharray: 1, 150
    stroke-dashoffset: 0
  
  50% 
    stroke-dasharray: 90, 150
    stroke-dashoffset: -35
  
  100% 
    stroke-dasharray: 90, 150
    stroke-dashoffset: -124
  
