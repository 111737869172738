@import ../../../styles/helpers

.main
    padding-top: 40px
    +d
        margin-bottom: 80px
    +m
        margin-bottom: 32px
        padding-top: 40px

.head
    max-width: 730px
    margin: 0 auto 20px
    text-align: left
    +m
        margin-bottom: 32px

.title
    margin-bottom: 24px

.breadcrumbs
    justify-content: center