@import ../../../styles/helpers

.title
    margin: 0 -5px 32px
    text-align: center

.info
    margin-bottom: 4px
    text-align: center
    +caption-2
    color: $n3
    +dark
        color: $n4

.text
    margin-bottom: 24px
    text-align: center
    color: $n4
    span
        font-weight: 500
        color: $n2
        +dark
            color: $n8

.code
    display: flex
    margin: 0 -8px

.number
    margin: 0 8px
    input
        width: 100%
        height: 80px
        border-radius: 8px
        border: 1px solid $n7
        background: $n7
        text-align: center
        +dm-sans
        font-size: 32px
        font-weight: 600
        color: $n2
        transition: all .2s
        &:focus
            border-color: $n6
            background: $n8
        +dark
            background: $n2
            border-color: $n2
            color: $n8
            &:focus
                background: none
                border-color: $n3

.button
    width: 100%
    margin-top: 8px

.resendOtpText
    text-align: center

.danger
    color: $p3