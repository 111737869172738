@import ../../styles/helpers

.customLogo
    img
        width: 90%
        margin-bottom: 20px
        &:first-child
            display: none
            +t
                display: inline-block
                +dark
                    display: none
        &:nth-child(2)
            +t
                display: none
                +dark
                    display: inline-block