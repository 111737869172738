@import ../../../styles/helpers


.card
    flex: 0 0 calc(25% - 38px)
    width: calc(25% - 32px)
    margin: 0 0px
    padding: 18px
    border-radius: 16px
    color: $n2
    transition: all .2s
    +d
        padding: 24px 16px
    +t
        padding: 16px 0
    +m
        display: flex
        width: 100%
        margin: 0
        padding: 16px 24px
    +dark
        color: $n8
    &:hover
        box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1)
        background: $n8
        +t
            box-shadow: none
            background: none
        +dark
            background: $n3
            +t
                background: none
