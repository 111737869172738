@import ../../../../../styles/helpers

.coinCard
    flex: 0 0 auto
    width: 320px
    +dark
        background-color: #2b2b2f
        border: 2px solid #444
    +m
        width: 100%

.firstCard
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1)
    +caption-bold-1 
    font-weight: 600
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    height: 140px
    margin-bottom: 5px
    background-color: #fff
    border-radius: 10px
    +dark
        color: $n8
        background-color: #2b2b2f
        border: 2px solid #444

.secondCard
    display: flex
    justify-content: space-between
    height: 150px
    margin-bottom: 5px

.box
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2)
    display: flex
    border-radius: 10px
    flex-direction: column
    +caption-bold-1 
    font-weight: 600
    justify-content: center
    align-items: center
    flex: 1
    border-radius: 5px
    text-align: center
    +dark
        color: $n8
        background-color: #2b2b2f
        border: 2px solid #444
    &:first-child
      margin-right: 2.5px
    &:not(:first-child)
      margin-right: 2.5px

.positive
    color: $p4

.negative
    color: $p3

.image
    display: flex
    justify-content: space-between
    align-items: center
    gap: 10px
    margin-bottom: 5px

.icon
    width: 24pxpx
    height: 24px

.titleText
    margin-bottom: 5px