@import ../../../styles/helpers

.box
    display: flex
    margin-top: 32px
    background: $n7
    border-radius: 16px
    +m
        margin-bottom: -32px
        background: none
    +dark
        background: $n2
        +m
            background: none

.details
    max-width: 256px
    margin: 64px auto 0
    padding: 32px 48px
    border-radius: 32px 32px 0 0
    background: $n8
    +m
        margin-top: 0
        background: $n7
    +dark
        background: $n3

.code
    padding: 16px
    border-radius: 8px
    border: 2px dashed $p1
    img
        width: 100%
        border-radius: 8px

.button
    width: 100%
    margin-top: 48px
    +dark
        box-shadow: inset 0 0 0 2px $n4
        &:hover
            box-shadow: inset 0 0 0 2px $n5

.top
    margin-bottom: 32px
    padding-bottom: 32px
    border-bottom: 1px solid $n6
    +dark
        border-color: $n3

.title
    margin-bottom: 32px
    text-align: center

.info
    margin-bottom: 12px
    text-align: center
    +caption-2
    color: $n4

.correct
    display: flex
    justify-content: center
    align-items: center
    height: 40px
    padding: 0 24px
    background: $n7
    border-radius: 20px
    font-weight: 500
    color: $p4
    +dark
        background: $n3
    svg
        margin-right: 4px
        fill: $p4
    span
        color: $n2
        +dark
            color: $n8

.image
    display: block
    margin-left: auto
    margin-right: auto
    width: 50%

.hideBarCode
    filter: blur(7px)
    display: block
    margin-bottom: 20px
    margin-top: 20px
    margin-left: auto
    margin-right: auto
    width: 50%

.textClass
    color: $n2
    margin: 10px 0 0 
    font-weight: 400
    font-size: 12px
    line-height: 20px
    +dark
        color: $n8

.linkText
    color: #22767D

.expiredText
    text-align: center
    margin-top: 20px

.boxContainer
    padding: 10px
    background: $n7
    border-radius: 24px
    margin-top: 32px
    +t
        margin: 0 -20px
    +m
        display: block
        margin: -32px -16px 0
        padding: 24px
    +dark
        background: $n2

.checkboxContainer
    text-align: center