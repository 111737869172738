@import ../../../styles/helpers

.container
    max-width: 1440px
    padding: 0

.info
    max-width: 350px
    margin: 0 auto 40px
    text-align: center
    font-weight: 500
    color: $n4

.item
    color: $n2
    transition: color .2s
    +dark
        color: $n8
    &:hover
        color: $p1
        .preview
            img
                transform: scale(1.1)

.preview
    position: relative
    display: flex
    justify-content: center
    align-items: center
    height: 296px
    overflow: hidden
    img
        max-width: 100%
        max-height: 100%
        transition: transform 1s

.category
    position: absolute
    top: 12px
    right: 12px
    z-index: 2

.body
    padding: 40px 40px 0

.title
    margin-bottom: 16px
    +body-bold-2

.content
    color: $n4
