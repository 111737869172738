@import ../../styles/helpers

.cookieBanner
    position: fixed
    bottom: 0
    left: 0
    width: 100%
    background-color: #e9eff6
    padding: 16px
    display: flex
    justify-content: space-between
    align-items: center
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1)
    z-index: 3

    +dark
        background-color: #1a1a1a
        box-shadow: 0px -2px 10px rgba(255, 255, 255, 0.1)

    .cookieContent
        display: flex
        flex-direction: column
        gap: 4px

    .cookieTitle
        font-size: 16px
        font-weight: bold
        color: #000

        +dark
            color: #fff

    .cookieDescription
        +caption-2
        color: #333

        +dark
            color: #bbb

    .cookieLink 
        color: #007bff
        text-decoration: underline
        cursor: pointer

        &:hover
            text-decoration: none

        +dark
            color: #4da6ff

    .cookieActions
        display: flex
        gap: 12px
        align-items: center

    .cookieSettings
        background: none
        border: 1px solid #22767d
        color: #22767d
        padding: 8px 12px
        border-radius: 5px
        cursor: pointer
        transition: background 0.2s

        +dark
            border: 1px solid #22767d
            color: #22767d

    .cookieAccept
        background: #22767d
        color: white
        padding: 8px 12px
        border: none
        border-radius: 5px
        cursor: pointer
        transition: background 0.2s

        &:hover
            background: #22767d

        +dark
            background: #22767d
            color: #fff

            &:hover
                background: #22767d
