@import ../../../styles/helpers

.title
    margin-bottom: 32px
    text-align: center

.info
    margin-bottom: 32px
    text-align: center
    +caption-2
    color: $n4

.variants
    display: flex
    flex-direction: column

.radio
    &:not(:last-child)
        margin-bottom: 16px
        padding-bottom: 16px
        border-bottom: 1px solid $n6
        +dark
            border-color: $n3

.button
    width: 100%

.code
    display: flex
    margin: 0 -8px 32px

.number
    input
        width: 100%
        height: 70px
        border-radius: 8px
        border: 1px solid $n7
        background: $n7
        text-align: center
        +dm-sans
        font-size: 32px
        font-weight: 600
        color: $n2
        transition: all .2s
        &:focus
            border-color: $n6
            background: $n8
        +dark
            background: $n2
            border-color: $n2
            color: $n8
            &:focus
                background: none
                border-color: $n3

.danger
    color: $p3
    text-align: center