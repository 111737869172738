@import ../../styles/helpers

.player
    position: relative
    &:before
        content: ""
        display: block
        padding-bottom: 53.6%
        +d
            padding-bottom: 69%
        +m
            padding-bottom: 150%
    img
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        border-radius: 16px
        object-fit: cover
    
.control
    position: absolute
    left: 8px
    right: 8px
    bottom: 8px
    z-index: 3
    display: flex
    align-items: center
    height: 40px
    padding: 0 20px
    border-radius: 20px
    background: $n8
    +dark
        background: $n1

.button
    svg
        fill: $n4
        transition: fill .2s
    &:hover
        svg
            fill: $n2
            +dark
                fill: $n8
    &:not(:last-child)
        margin-right: 24px
        +m
            margin-right: 16px
    
.line
    position: relative
    flex-grow: 1
    height: 8px
    margin-right: 24px
    background: $n6
    border-radius: 4px
    +m
        margin-right: 16px
    +dark
        background: $n3

.progress
    position: absolute
    top: 0
    left: 0
    bottom: 0
    border-radius: 4px
    background: $p1

.time
    margin-right: 24px
    +body-bold-2
    +m
        margin-right: 16px