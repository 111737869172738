@import ../../styles/helpers

.semiCircularContainer 
    width: 150px
    height: 80px
    position: relative

.chartValue 
    position: absolute
    left: 50%
    transform: translateX(-50%)

.chartValue.top
    top: 30%

.chartValue.bottom
    top: 70%
    font-size: 11.5px
    text-align: center