@import ../../styles/helpers

.VersionHeader
    text-align: center
    display: flex
    justify-content: center
    align-items: center
    background-color: #222
    color: #fff
    font-family: Arial, Helvetica, sans-serif

.TVChartContainer
    height: 485px !important