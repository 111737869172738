@import ../../styles/helpers

.container
    +d
        padding: 0 40px
    +m
        padding: 0 32px

.head
    max-width: 455px
    margin: 0 auto 16px
    text-align: center
    +m
        margin-bottom: 48px
        text-align: left

.title
    margin-bottom: 20px
    span
        color: $p4

.info
    +body-2
    color: $n4

.wrapper
    margin: 0 -12px
    +m
        margin: 0 -4px

.btns
    margin-top: 64px
    text-align: center
    +t
        margin-top: 40px
    .button
        +m
            width: 100%