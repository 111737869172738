@import ../../../../../styles/helpers

.head
    display: flex
    align-items: center
    justify-content: space-between
    margin-bottom: 16px

.title
    +body-bold-1

.counter
    +caption-bold-2
    svg
        margin-right: 4px

.field
    display: flex
    align-items: center
    padding: 0 16px
    border-radius: 12px
    box-shadow: inset 0 0 0 2px $n6
    cursor: pointer
    +dark
        box-shadow: inset 0 0 0 2px $n2
    &:not(:last-child)
        margin-bottom: 6px

.input
    flex-grow: 1
    height: 48px
    padding: 0 10px
    background: none
    text-align: right
    +poppins
    font-size: 14px
    font-weight: 500
    color: $n2
    +dark
        color: $n8

.label,
.currency
    font-weight: 500
    color: $n4
    pointer-events: none

.button
    width: 100%

.warning
    color: red
    
.errorContainer
    height: 24px

.errorMessage
    color: red
    font-size: 14px

.errorContainer 
    visibility: hidden

.errorContainer .errorMessage
    visibility: visible

.hideClass
    visibility: hidden
    display: flex
    align-items: center
    padding: 0 16px
    border-radius: 12px
    box-shadow: inset 0 0 0 2px $n6
    cursor: pointer
    +dark
        box-shadow: inset 0 0 0 2px $n2
    &:not(:last-child)
        margin-bottom: 6px

.danger
    color: red
    font-size: 12px

.estFee
    display: flex
    visibility: hidden
    align-items: center
    justify-content: space-between
    padding: 10px 8px
    +caption-bold-1

.slippage
    display: flex
    justify-content: flex-end
    padding: 2px 8px
    cursor: pointer

    .tooltip
        position: relative
        display: inline-block
        border-bottom: 1px dotted black

    .tooltip .tooltiptext 
        visibility: hidden
        width: 250px
        background-color: $n3
        color: #fff
        text-align: left
        border-radius: 6px
        padding: 10px
        position: absolute
        z-index: 1
        top: -100px
        right: 110%

    .tooltip .tooltiptext::after
        content: ""
        position: absolute
        top: 50%
        left: 100%
        margin-top: -5px
        border-width: 5px
        border-style: solid
        border-color: transparent transparent transparent black

    .tooltip:hover .tooltiptext
        visibility: visible        