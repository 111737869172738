@import ../../styles/helpers

.container
    max-width: 1132px
    margin: 60px auto
.head
    max-width: 545px
    margin: 0 auto 16px
    text-align: center
    +t
        margin-bottom: 48px
    +m
        margin-bottom: 24px

.logo
    margin-bottom: 2px

.title
    margin-bottom: 20px
    span
        color: $p4

.info
    +body-2
    color: $n4