@import ../../styles/helpers

.label
    margin-bottom: 12px
    +hairline-2
    color: $n5

.dropdown
    position: relative
    z-index: 3
    &.active
        z-index: 10
        .head
            .arrow
                svg
                    transform: rotate(180deg)
        .body
            max-height: 300px
            overflow: auto
            visibility: visible
            opacity: 1
            transform: translateY(0)
            
.head
    position: relative
    height: 48px
    padding: 0 48px 0 16px
    border-radius: 12px
    box-shadow: inset 0 0 0 2px $n6
    background: $n8
    font-size: 14px
    font-weight: 500
    line-height: 48px
    cursor: pointer
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    user-select: none
    transition: box-shadow .2s
    +dark
        box-shadow: inset 0 0 0 2px $n3
        background: none
    &:hover
        box-shadow: inset 0 0 0 2px $n4

.selection
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

.arrow
    position: absolute
    top: 50%
    right: 8px
    display: flex
    justify-content: center
    align-items: center
    width: 32px
    height: 32px
    border-radius: 50%
    transform: translateY(-50%)
    svg
        fill: $n2
        transition: transform .2s
    +dark
        svg
            fill: $n4

.body
    position: absolute
    top: calc(100% + 2px)
    left: 0
    right: 0
    padding: 8px 0
    border-radius: 12px
    background: $n8
    box-shadow: inset 0 0 0 2px $n6, 0 4px 12px rgba($n2, .1)
    visibility: hidden
    opacity: 0
    transform: translateY(3px)
    +dark
        background: $n1
        box-shadow: inset 0 0 0 2px $n3, 0 4px 12px rgba($n1, .1)
     
      

.option
    padding: 8px 16px
    font-size: 14px
    font-weight: 500
    transition: color .2s
    cursor: pointer
    -webkit-tap-highlight-color: rgba(0,0,0,0)
    &:hover,
    &.selectioned
        color: $p1

.empty
    .head
        height: 26px
        padding: 0 36px 0 14px
        box-shadow: none
        background: none
        line-height: 26px
        &:hover
            box-shadow: none
    .arrow
        right: 12px
        width: 24px
        height: 24px
        box-shadow: none
    .option
        padding: 8px 14px