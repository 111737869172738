@import ../../../styles/helpers

.main
    position: relative
    padding-top: 100px
    min-height: 300px
    background: #caebe0
    +m
        min-height: auto
        padding: 64px 0 112px
    +dark
        background: #18191D

.wrap
    position: relative
    z-index: 3
    max-width: 545px
    margin-bottom: 143px
    +m
        margin-bottom: 8px

.title
    margin-bottom: 32px
    +m
        margin-bottom: 16px
        font-size: 40px
        line-height: 48px

.text
    color: $n3
    span
        color: $p4
    +m
        font-size: 16px
    +dark
        color: $n5
    strong
        font-weight: 600

